<template>
    <div class="pa-0 ma-0 mobile-page" :class="classProps">
        <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize" :class="labelCls">{{label}}</v-subheader>
        <slot name="input"></slot>
    </div>
</template>

<script>
export default {
    props:["label", "classProps", "labelCls"],
    name: 'CustomInput',
};
</script>
