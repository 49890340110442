<template>
    <div class="pa-0 ma-0" :class="classProps">
        <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">{{label}}{{required ? '*' : ''}}</v-subheader>
        <v-select v-if="!combobox" class="rounded-lg text-capitalize" color="primary" hide-details="auto" :placeholder="placeholder ? placeholder :label"
            :items="items" label="" outlined></v-select>
        <v-combobox v-else class="rounded-lg text-capitalize" color="primary" hide-details="auto" :placeholder="placeholder ? placeholder :label"
            :items="items" label="" outlined></v-combobox>
    </div>
</template>

<script>
export default {
    props:["label", "combobox", "required", "placeholder", "classProps", "items"],
    name: 'Dropdown',
};
</script>
